/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { union } from "lodash";

interface SEOProps {
  title: string;
  lang?: string;
  description?: string;
  meta?: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[];
  includeMainTitle?: boolean;
  image?: any;
}

function SEO({
  description = "",
  lang = "en",
  meta = [],
  title,
  includeMainTitle = true,
  image,
}: SEOProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  // const titleTemplate = includeMainTitle
  //   ? `%s | ${site.siteMetadata.title}`
  //   : `%s`;
  const fullTitle = includeMainTitle
    ? `${title} | ${site.siteMetadata.title}`
    : title;
  var metaDefaults: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[] = [
    { name: `description`, content: metaDescription },
    { property: `og:title`, content: fullTitle },
    { property: `og:description`, content: metaDescription },
    { property: `og:type`, content: `website` },
    { name: `twitter:card`, content: `summary` },
    { name: `twitter:creator`, content: site.siteMetadata.author },
    { name: `twitter:title`, content: fullTitle },
    { name: `twitter:description`, content: metaDescription },
  ];
  if (image) {
    metaDefaults = union(metaDefaults, [{ name: "og:image", content: image }]);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={fullTitle}
      // titleTemplate={titleTemplate}
      meta={union(metaDefaults, meta)}
    />
  );
}

export default SEO;
