import React from "react";
import { PLAY_STORE_URL } from "../values";
import MyLink from "./MyLink";

import PlayBadge from "../images/google-play-badge.png";

interface Props {
  href?: string;
  eventName: string;
}

const PlayStoreIcon = ({ href = PLAY_STORE_URL, eventName }: Props) => (
  <MyLink
    href={href}
    linkType="outbound_link"
    eventName={eventName}
    style={{ height: 40, width: 130 }}
  >
    <img
      // style={{ height: 40, width: 120 }}
      alt="Get it on Google Play"
      src={PlayBadge}
    />
  </MyLink>
);
export default PlayStoreIcon;
