import React from "react";
import AppStoreDownload from "../components/AppStoreDownload";
import PlayStoreDownload from "../components/PlayStoreDownload";
import { APP_STORE_URL } from "../values";
import MyLink from "./MyLink";

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  main: [
    {
      name: "Support",
      href: "mailto:support@one-step.io?&subject=Support - Web",
      eventName: "footer_support_clicked",
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-white">
      <div className="mx-auto py-8 mt-4 sm:mt-0 overflow-hidden px-10 lg:px-20">
        <nav
          className="flex flex-wrap gap-8 sm:gap-4 justify-center sm:justify-between items-center"
          aria-label="Footer"
        >
          <div
            className="sm:-mx-5 -my-2 flex gap-4 justify-center"
            aria-label="Footer-App-Store"
          >
            <AppStoreDownload
              href={APP_STORE_URL}
              eventName="footer_app_store_clicked"
            />
            <PlayStoreDownload eventName="footer_play_store_clicked" />
          </div>
          <div className="gap-4 flex">
            {navigation.main.map((item) => (
              // <div  className="px-5 py-2">
              <MyLink
                key={item.name}
                href={item.href}
                linkType="event_link"
                eventName={item.eventName}
                className="px-5 py-2 text-base text-gray-500 hover:text-gray-900"
              >
                {item.name}
              </MyLink>
              // </div>
            ))}
            {/* <div className="text-base text-gray-400">
              &copy; {new Date().getFullYear()} One Step LLC
            </div> */}
          </div>
        </nav>
      </div>
    </footer>
  );
}
