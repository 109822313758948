export const trackLinkClick = (
  linkEvent: string,
  style: "outbound" | "internal",
  href?: string,
  details?: object
) => {
  if (typeof window !== "undefined" && typeof window.amplitude === `object`) {
    window.amplitude.getInstance().logEvent(linkEvent, {
      location: _locationName(),
      href,
      link_destination: style,
      ...details,
    });
    // console.log("log_event___", linkEvent, "  -  ", _locationName());
  } else {
    // console.log("log_event_skipped__", linkEvent, "  -  ", _locationName());
  }
};

export const trackEvent = (event: string, details?: object) => {
  if (typeof window !== "undefined" && typeof window.amplitude === `object`) {
    window.amplitude.getInstance().logEvent(event, {
      location: _locationName(),
      ...details,
    });
    // console.log("log_event___", event, details);
  } else {
    // console.log("log_event_skipped__", event, details);
  }
};

export default trackLinkClick;

declare global {
  interface Window {
    amplitude?: any;
  }
}

function _locationName() {
  if (location && location.pathname) {
    const name = location.pathname;
    if (name == "/") {
      return "home";
    } else if (name.endsWith("/")) {
      return name.slice(0, -1);
    }
    return name;
  }
  // console.log({
  //   pathname: location.pathname,
  //   search: location.search,
  //   hash: location.hash,
  // });
  return undefined;
}
